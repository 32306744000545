/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { Component } from "react";
import PropTypes from "prop-types";

// import Header from "./header";
import NavBar from "../NavBar";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      env: "server",
    };
  }

  componentDidMount() {
    this.setState({ env: "client" });
  }

  render() {
    const { env } = this.state;

    return (
      <>
        {/* Force the navbar to re-render so it changes correctly based on
        logged in status. Without this Gatsby assumes the client and server
        have identical first renders which isn't true for components that depend
        on runtime state. */}
        {/* <NavBar env={env} /> */}
        {env === "client" ? <NavBar /> : null}
        <div
          style={{
            backgroundColor: "#f0f2f5",
          }}
        >
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0 1.0875rem 1.45rem`,
            }}
          >
            <main>{this.props.children}</main>
            <footer
              style={{
                marginTop: `2rem`,
              }}
            ></footer>
          </div>
        </div>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
